.itemDestc{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 48px;
    gap: 36px;
    background: rgba(217, 217, 217, 0.04);
    border-radius: 16px;
    
}

.text_side{
    text-align: left;
}

.text_side h3{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 30px;
    /* identical to box height */
    
    
    /* blanco */
    
    color: #FFFFFF;
}
.text_side p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    
    /* blanco */
    
    color: #FFFFFF;
}

#comprarfacil{
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.mercanciaprohibidas,.queesvolumen{
    margin-top: 100px;
    padding: 30px;
}
.mercanciaprohibidas .listado img{
    margin-right: 20px;     vertical-align: middle;
}
.mercanciaprohibidas .listado p {
    padding: 10px;
    
}
.mercanciaprohibidas .listado{
    color: white;
    text-align: left;
    margin-top: 36px;
}
.mercanciaprohibidas{
    text-align: left;
}
.queesvolumen h3{
    text-align: left;
}

.queesvolumen p {
    color: white;
    text-align: left;
    margin-top: 36px;
}
.queesvolumen ul {
    text-align: left;
    color: white;
    margin-top: 20px;
    margin-left: 10px;
}

.queesvolumen .amarillo{
    color: #FFC800;
}

.collapse_preguntasfaq
{
    margin-top: 36px;
    text-align: left;
    padding: 30px;
}

.collapse_preguntasfaq .ant-collapse-header-text:hover, .collapse_preguntasfaq .ant-collapse-item:hover .ant-collapse-header-text{
    color: var(--celeste) !important;
}

.collapse_preguntasfaq .ant-collapse-header-text{
    font-size: 18px;
    font-weight: 600;
    text-align: left;
}
.collapse_preguntasfaq .ant-collapse-item-active
{
    
background: rgba(217, 217, 217, 0.04);
/* border-bottom: 0px solid #d9d9d9 !important; */
/* celeste */

border: 1.5px solid #39BBDD;
box-shadow: 0px 0px 0px 3px rgba(57, 187, 221, 0.36);
border-radius: 16px !important;

}

.collapse_preguntasfaq .ant-collapse-content-box{
    text-align: left;
}

.collapse_preguntasfaq .ant-collapse-header-text, .collapse_preguntasfaq .ant-collapse-content-box
{
    color: white !important;
}

.collapse_preguntasfaq .ant-collapse-item{
    margin: 23px;
    background: rgba(217, 217, 217, 0.04);
border-radius: 16px;border-bottom: 0px solid #d9d9d9 !important;

/* height: 96px; */
}
.collapse_preguntasfaq .ant-collapse-header{
    height: 100%;
    padding: 36px !important;
}
.collapse_preguntasfaq .ant-collapse-expand-icon{
    order: 1;
    color: white;
}

@media (max-width: 800px){
    .itemDestc{
        flex-direction: column;
    }
    .mercanciaprohibidas, .queesvolumen{
        padding: 30px;
    }
    .mercanciaprohibidas .listado img{
        margin-right: 0px;
    }
}