.busqueda_bar{
    display: flex;
    justify-content: space-around;
    max-width: 700px;
    margin: 0 auto;

}

.busqueda_bar .ant-input{
    height: 50px !important;
    margin-right: 30px;
}

.btn_contactar
{
    display: flex;
    align-items: center;
    justify-content: center;
    align-items:center;
    padding: 10px;
}

.btn_buscar{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 130px;
    align-items:center
}

.arrow_amarillo{
    background-image: url('./../assets/img/arrow_amarillo.png');
    height: 64px;
    width: 142px;
    left: 30px;
    margin-left: 20px;
    position: absolute;
}

.txt_info{
    font-family: 'Inter';
    font-style: italic;
    font-weight: 500;
    font-size: 18x;
    line-height: 24px;
    position: relative;
    top: -80px;
    left: -80px;
    /* blanco */
    
    color: #FFFFFF;
}
.cont_marcas{
/* Auto layout */
flex-wrap: wrap;
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 16px;
width: 100%;
justify-content: space-evenly;
}
.item_marcas{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;
    gap: 36px;
    background: rgba(217, 217, 217, 0.04);
    border-radius: 16px;    
}
#marcas{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 74px;
    padding: 30px;
}

.destacadoHome  .titulo_degradado{
    font-weight: 900;
font-size: 33px !important;
line-height: 46px;
/* identical to box height */

text-transform: uppercase;
}
.destacadoHome  .linea-flex{
    justify-content: flex-end;
}
.destacadoHome .rowItemServ div{
    text-align: right;
}

#destacado_home_bajo{
    padding: 30px !important;
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 80px;
    margin-top: 90px;
}


@media (max-width: 700px) {
    #rastrearPaquete,#destacado_home_bajo,#somosEmpresa,#serviciosFullCards{
        padding: 32px
    }
    .cont_marcas{
        flex-wrap: wrap;
    }
    .item_marcas
    {
        min-width: 30%;
        max-width: 30%;
        height: 0px;
    }
    .linea-flex{
        text-align: left !important;
    }
}
