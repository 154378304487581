#formContacto{
    padding: 30px;
    display: flex;
    flex-direction: row !important;   
    gap: 40px;
}
.formContact{
    width: 60%;
}
.datosBox{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 36px;
    gap: 36px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 16px;
    color: white;    
    max-width: 30%;
}
.datosTxt{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 36px;
}

 .grisText
{
    color: #84889C
    ;
    text-align: left;
}

#formContacto span.data_W{
    font-weight: 900;
font-size: 13px;
text-align: left;
margin-top: 10px !important;
line-height: 23px;
display: block;
/* identical to box height */

text-transform: uppercase;

/* blanco */

color: #FFFFFF;
}

.ant-form-item-control-input-content textarea{
height: auto !important;
}

@media (max-width: 870px) {
    #formContacto{
        flex-direction: column !important;
    }
    .datosBox,.formContact{
        max-width: inherit;
        width: inherit;
    }

}