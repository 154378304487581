#somosEmpresa{
    text-align: left;
    margin-top: 50px;
    margin-bottom: 50px;
}

.itemSomos{
    display: flex;
    gap: 24px;
    padding: 30px;

}

#somosEmpresa h2{
    width: 100%;
    text-align: center;
}

#somosEmpresa .itemSomos h3{
    margin-bottom: 10px;
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 29px;

/* identical to box height */


/* blanco */

color: #FFFFFF;
}

#somosEmpresa .itemSomos p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    
    /* blanco */
    
    color: #FFFFFF;
    
}



