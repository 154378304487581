.rowItemServ {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    /* gap: 86px; */
    
}
.itemServ{
    text-align: left;
}

.rowItemServ p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    
    /* blanco */
    
    color: #FFFFFF;
    
}

.rowItemServ img{
    max-width: 100%;
}

.rowItemServ .ant-col
{
    display: flex;
    flex-direction: column;
    /* order: 1; */
}
.rowItemServ div{
    padding: 10px;
}

.rowItemServ .order1{
order: 1;
}

.linea-flex{
    display: flex;
}
.rowItemServ  p{
    margin-top:10px;
}

#serviciosFullCards{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 120px;
}