.heroComponent h1, .titulo_degradado{
    font-style: normal;
    font-weight: 900;
    font-size: 46px;
    /* font-size: calc(1.65em + 5vmin); */
    line-height: 61px;
    text-transform: uppercase;
    
    background: linear-gradient(90deg, #39BBDD 0%, #C7F3FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    
}
.heroComponent{
    padding: 20px;
}

.heroComponent p {
    margin-top: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    
    /* blanco */
    
    color: #FFFFFF;
}

.cont_text_hero{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
}
.rowHero{
    padding-top: 50px;
    padding-bottom: 50px;
}

@media (max-width: 700px) {
    .rowHero img{
        width: 100%;

    }
    .img_hero{
        padding: 30px;
    }
    .heroComponent h1, .titulo_degradado{
            font-size: calc(1.65em + 5vmin);
    }
    .txt_info
    {
        display: none;
    }
}

