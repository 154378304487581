/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;900&display=swap');
@import url('/src/css/variables.css');

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

*{
  font-family: 'Inter' !important;
font-style: normal;
font-weight: 500;
}



html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



body{
  background: linear-gradient(180deg, #000B40 0%, #111C4E 100%);}

.headerPage{
  background: rgba(7, 15, 52, 0.01);
border-bottom: 1.5px solid rgba(57, 187, 221, 0.24);
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  background-color: #070F3403;
}
#menuprincipal{
  color: white;
}

#menuprincipal a{
  color: white;
  text-decoration: none;
}

#menuprincipal ul {
  display: inline-flex;
}

#menuprincipal ul li {
  padding: 10px;
}

.btn_micuenta{
  background: rgba(57, 187, 221, 0.16);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  color: white;
  gap: 12px;  
  cursor: pointer;
}

.btn_register{
  background: rgba(57, 187, 221, 0.16);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  color: white;
  gap: 12px;
}

.btn_register span{
  background: rgba(7, 15, 52, 0.01);
  border-radius: 17px;
  padding: 2px;
  border: solid 1px;
  border-radius: 17px;
  padding: 2px;
}


.calltoaction{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 36px;
  gap: 36px;
  
  /* position: absolute; */
  /* width: 1034px;
  height: 114px;
  left: 443px;
  top: 761px; */
  margin: 0 auto;
  
  background: rgba(255, 255, 255, 0.04);
  border-radius: 16px;
}

.calltoaction h2{
  /* font-family: 'Mont'; */
  font-style: normal;
  font-weight: 900;
  /* font-size: 24px; */
  font-size: calc(0.65em + 2.4vmin);
  line-height: 31px;
  /* identical to box height */
  
  text-transform: uppercase;
  font-feature-settings: 'ss01' on;
  
  /* blanco */
  
  color: #FFFFFF;
  
  
  /* Inside auto layout */
  


}


@media (min-width: 760px) AND (max-width:970px) {
  .cta_dudas h3{
    font-size: 16px !important;
    text-align: left;
    max-width: 50% !important;
  }
}


.cta_dudas .txt_info{
  left: -180px;
  top: 0px;
}
.cta_dudas .txt_info p{
  max-width: 183px;
}

.cta_dudas h3{
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 31px;
  text-transform: uppercase;
  font-feature-settings: 'ss01' on;
  color: white;  
  max-width: 370px;
}
.row_cta_dudas{
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  width: 100%;
}
.cta_dudas .botonPrimario{
width: auto;
}
.cta_dudas{
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 48px 36px;
  gap: 36px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 16px;
  /* width: 100%; */
  width: 80%;
  margin-top: 60px !important;
  margin: 0 auto;
}

.btn_cta{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  
  width: 242px;
  height: 42px;
  
  /* celeste-botón */
  
  background: #1FB1D7;
  border-radius: 8px;
  color: white;
}


section#login_register{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
  padding: 0px;
  gap: 74px;
  width: 100%;
  height: 529px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 16px;
  margin-bottom: 40px;
  margin-top: 100px;
  overflow: hidden;
  position: relative;
  align-items: center;
}

.contenedor{
  margin: 0 auto;
  max-width: 1400px;
}

.line_separador{
  border: 1.5px solid rgba(57, 187, 221, 0.24);
  transform: rotate(90deg);
  width: 100%;
  transform-x: 4px;
  top: 0px;
  transform-origin: center top 1111111cm;
  position: absolute;
  overflow: hidden;
}

#login_cuadro{
  /* height: 100%;
  margin-top: 30px; */
  min-height: 300px;
  max-height: 300px;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
}


#login_cuadro h2{
  font-weight: 900;
  font-size: 30px;
  line-height: 41px;
  color: white;
  text-align: left;
}

.contenedor#pageRegistrar #formDatosUser{
  max-width:80% ;
  margin: 0 auto;
  margin-top: 50px;
}

#formLogin label, #formDatosUser label, #formContacto label{
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  
  /* blanco */
  
  color: #FFFFFF;
  
}
.ant-picker-input svg{
  color: white;
}
.anticon-close-circle{
background-color: #1FB1D7;
color: red;
}

#basic_fechaNacimiento{
  border: solid 0px !important;
}

.ant-form-item-control-input-content .ant-picker{
  background-color: transparent !important;
  height: 42px !important;
  border: 1.5px solid #484E6E !important;

  color: white !important;
}

.ant-form-item-control-input-content input, .ant-select-selector, .ant-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 10px;
  
  /* width: 280px; */
  height: 42px !important;
  color: white !important;
  /* gris-4 */
  
  border: 1.5px solid #484E6E !important;
  border-radius: 8px;
  background-color: transparent !important;
}
.ant-select-selector{
  text-align: left;
}
span.ant-select-arrow
{
  color: white !important;
}

input#basic_email{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 10px;
  
  width: 280px;
  height: 42px;
  color: white;
  /* gris-4 */
  
  border: 1.5px solid #484E6E;
  border-radius: 8px;
  background-color: transparent !important;
}

input#basic_clave{
  background-color: transparent !important;
  background-color: transparent;
  border-left: 0px white solid !important;
  border-right: 0px white solid !important;
    border: 0px;
    color: white;
}

.ant-input-password span svg{
  color: white;
}

#formLogin .ant-input-password{
  border: solid 1.5px #484E6E;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 10px;
  
  width: 280px;
  height: 42px;
  background-color: transparent !important;
}

#formLogin input:-internal-autofill-selected {
background-color: transparent !important;
-webkit-box-shadow: 0 0 0 30px #0c1648 inset !important;
color: whitesmoke !important;
-webkit-text-fill-color:whitesmoke !important;
border-top: solid 1px #4096ff  !important;
border-bottom: solid 1px #4096ff  !important;
border-left: solid 0px #4096ff  !important;
border-right: solid 0px #4096ff  !important;


}



.botoniniciar
{
  background: #1FB1D7;
border-radius: 8px !important;
width: 100%;
}

.link_recuperar_pass
{
  text-align: left;
  margin-bottom: 20px;
}


/* #formLogin :where(.css-dev-only-do-not-override-26rdvq) .ant-input-affix-wrapper,
#formLogin :where(.css-dev-only-do-not-override-26rdvq).ant-form-item .ant-form-item-control-input-content{
background-color: transparent !important;
} */


/* BENEFICIOS */

.beneficios{
  width: 280px;
height: 100%;
color: white;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
}

.beneficios li{
  margin-bottom: 20px;
}

.beneficios li svg{
  color: var(--celeste);
  margin-right: 5px;
}

.beneficios h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  margin-bottom: 15px;
}

.beneficios ul {
  display: block;
    text-align: left;
}

.btn_outline{
  border: var(--celeste) solid ;
  border-radius: 8px;
  height: 30px;
  line-height: 30px;
  color: var(--celeste);

}
.link_recuperar_pass a{
  color: white;
}
.wrap_cuenta h2.titulo{
  font-style: normal;
font-weight: 900;
font-size: 22px;
line-height: 31px;
/* identical to box height */

text-transform: uppercase;

/* blanco */

color: #FFFFFF;
}

/* RESPONSIVO */

@media (max-width: 800px) {
  /* … */

  body{
    /* background-color: red !important; */
  }
  .headerPage{
    flex-direction: column;
  }
  #menuprincipal a {
    font-size:10px !important;
  }
}

@media (max-width: 700px) {
  section#login_register{
    height: auto !important;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .line_separador{
    top: 50%;
    transform: rotate(0deg);
  }
}

@media (max-width: 800px) {
  
  .row_cta_dudas{
    width: 100% !important;
    flex-wrap: wrap !important;
    justify-content: center;
    gap: 20px;
  }
}


.modal-idealbox .ant-modal-content{
/* azul */

background: #111C4E;
/* gris-4 */

border: 1.5px solid #484E6E;
box-shadow: 0px 4px 8px rgba(132, 136, 156, 0.24);
border-radius: 16px;
height: 307px;
padding: 40px;

}

.modal-idealbox .ant-modal-title{
  background: #111C4E;

font-family: 'Mont';
font-size: 22px;
line-height: 28px;
text-transform: uppercase;

/* blanco */

color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.modal-idealbox .ant-modal-body{
  height: 150px;
  display: flex;
  align-items: center;
}

.modal-idealbox .ant-modal-footer{
  display: flex;
  justify-content: flex-end;
}

.modal-idealbox .ant-btn-default{
  
/* Auto layout */

/* display: flex; */
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 20px 16px 24px;
gap: 8px;

width: 156px;
height: 56px;

/* celeste-botón */

background: white;
border-radius: 8px;
}

.ant-modal-footer .ant-btn-primary{
  
/* Auto layout */

/* display: flex; */
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 20px 16px 24px;
gap: 8px;

width: 156px;
height: 56px;

/* celeste-botón */

background: #1FB1D7;
border-radius: 8px;

}

.ant-modal-body{
  color: white;
}
.id_vv_googlemaps {
  height: 400px;
  /* position:unset !important; */
}


.input-search-direccion{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 10px;
  width: 100%;
  height: 42px !important;
  color: white !important;
  border: 1.5px solid #484E6E !important;
  border-radius: 8px;
  background-color: transparent !important;
}

 .ant-table-tbody >tr >td.ant-table-cell-row-hover{
  background-color: transparent !important;
}