.ServiciosCardComponent{
    padding: 30px;
    margin-top: 30px;
}
.itemServicioCard{
    height: 372px;
    text-align: left;
    background: rgba(217, 217, 217, 0.04);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 48px;
    gap: 36px;
}

.itemServicioCard .titulo{
    font-style: normal;
    font-weight: 900;
    font-size: 34px;
    line-height: 46px;
    text-transform: uppercase;
    
    background: linear-gradient(90deg, #39BBDD 0%, #C7F3FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.itemServicioCard p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    
    /* blanco */
    
    color: #FFFFFF;
}

.botonVermas{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 8px;
    
    width: 148px;
    height: 56px;
    
    background: rgba(31, 177, 215, 0.16);
    border-radius: 8px;
}

@media (max-width: 1300px) AND (min-width:768px) {
    .itemServicioCard .titulo{
        font-size: 24px;
    }
    .itemServicioCard{
        padding: 20px;
    }
}