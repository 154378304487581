@import url('/src/css/variables.css');

.menu_lateral{
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 24px;
color: white;
text-align: left;
max-width: 250px;
min-width: 250px;
}

.menu_lateral li{
    margin-top: 30px;
    margin-bottom: 30px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 20px;
    padding-right: 20px;

}
.menu_lateral li svg{
    margin-right: 10px;
}

.menu_lateral a{
    color: white;
    text-decoration: none;
    width: 100%;
}

.menu_lateral .active svg{
    color: var(--celeste);
}

.menu_lateral .active{
    background: rgba(57, 187, 221, 0.16);
    border-radius: 8px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 20px;
    padding-right: 20px;
    
}


/* pagina de cuenta***/


section#micuenta
{
    width: 100%;
    padding: 30px;
}

#micuenta hr{
    border: 1px solid rgba(57, 187, 221, 0.24);
    margin-top: 50px;
    margin-bottom: 50px;
}

.wrap_cuenta{
    width: 100%;
    display: inline-flex;

}

.detalles_usuario{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 36px;
    gap: 36px;
    /* margin-top: 40px; */
    
    /* width: 90%; */
    
    background: rgba(255, 255, 255, 0.04);
    border-radius: 16px;
}

span.dato_label{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: var(--gris);
}

.detalles_usuario p {
    /* font-family: 'Mont'; */
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 23px;
    /* identical to box height */
    
    text-transform: uppercase;
    color: white;
}
.group_dato{
    height: 50px;
}
.ant-select-selection-item{
    /* color: rgb(17 132 255); */
    color: white !important;
}

.cont_btns{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.botonPrimario  a{
    color: whitesmoke;
}

.botonPrimario{
    background: #1FB1D7;
    border-radius: 8px !important;
    width: 100%;
    height: 50px;
    color: white;
    border-color: transparent;
}
.botonPrimario:hover{
    color: white !important;
}

.botonSecundario{
    background: rgba(57, 187, 221, 0.16);
border-radius: 8px;    width: 100%;
height: 50px;
color: white;
border-color: transparent;

}
@media (max-width: 500px) {

}

@media (max-width: 1050px) {
.wrap_cuenta{
    flex-direction: column;
}
.menu_lateral{
    max-width: fit-content;
    min-width: fit-content;
    margin:  0 auto;
}
.menu_lateral ul{
    display: flex;
        flex-wrap: wrap;
        justify-content: center;
}
.menu_lateral a{
    font-size: 0.8em;
}
#micuenta,section#paquetes,section#metodosPago,section#comprobantes{
    width: auto !important;
}
}