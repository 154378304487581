.logo_f{
    height: 60px;
}

.cont_links_footer{
    display: flex;
    color: white;
    order: 1;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 80px;
    
}

.cont_links_footer ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    
}

#footer{
    padding: 30px;
    border-top: 1.5px solid rgba(57, 187, 221, 0.24);

}

.cont_logo_f{
    order: 0;
    display: flex;
    flex-direction: column;

    justify-content: space-evenly;
}
#footer{
    margin-top: 80px;
    margin-bottom: 80px;
}

#footer footer{
    display: flex;
    justify-content: space-between;
}

.icons_line{
    display: flex;
    justify-content: space-around;
}
.text_copy{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    margin: 36px 0px;
    
    /* gris-3 */
    
    color: #84889C;
    
}
@media (max-width: 800px) {
    .cont_links_footer{
        flex-direction: column;
        align-items: flex-start;
    }
    #footer footer{
        flex-direction: column;
    }
    #footer{
        padding: 50px;
    }
    .logo_f{
        height: auto ;
        width: 50%;
        margin: 0 auto;    padding: 30px;
    }
    .cont_logo_f{
        margin-top: 30px;
        margin-bottom: 30px;
    }
}